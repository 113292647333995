import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { fetchBusinessById } from '../redux/actions/businessActions';
import EditStandardBlockModal from '../shared/components/EditStandardBlock';
import { createStandardBlock, getAvailableStandardBlocks } from '../redux/actions/standardBlockActions';
import AdminHeader from '../shared/components/AdminHeader';
import AdminBusinessMenu from '../shared/components/AdminBusinessMenu';

interface Props extends RouteComponentProps<{
  id: string;
}> {}

const AdminBusinessDetailStandardBlocks: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business,
    standardBlock,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
    standardBlock: store.standardBlockReducer,
  }));

  useEffect(() => {
    // fetch business when id param changes
    dispatch(fetchBusinessById(parseInt(match.params.id)));
    // fetch available standard blocks when id param changes
    dispatch(getAvailableStandardBlocks(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  const [creatingStandardBlock, setCreatingStandardBlock] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Business Detail" />
      <IonContent fullscreen class="ion-padding">
        {!business.business ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <AdminBusinessMenu businessId={business.business.id} />
            <IonText>
              <h1>Standard Blocks (for display on the A-Z page)</h1>
            </IonText>
            {business.business.standardBlocks.map((standardBlock: any) => (
              <IonItem key={standardBlock.id} routerLink={`/admin/businesses/${business.business?.id}/standard-blocks/${standardBlock.id}`}>
                <IonLabel>{standardBlock.name}</IonLabel>
              </IonItem>
            ))}
            {creatingStandardBlock &&
              <EditStandardBlockModal
                title="Create Standard Block"
                availableOptions={standardBlock.availableOptions}
                name=""
                //image=""
                type=""
                content=""
                linkUrl=""
                pdf=""
                submitText="Create"
                onCloseClick={() => setCreatingStandardBlock(false)}
                // onSubmitClick={(name: string, image: string, content: string, linkUrl: string) => {
                //   return dispatch(createStandardBlock({ name, image, content, linkUrl, businessId: business.business.id }, () => {
                onSubmitClick={(name: string, type: string, content: string, linkUrl: string, pdf: string) => {
                  return dispatch(createStandardBlock({ name, type, content, linkUrl, pdf, businessId: business.business.id }, () => {
                    setCreatingStandardBlock(false);
                    dispatch(fetchBusinessById(business.business.id));
                    dispatch(getAvailableStandardBlocks(business.business.id));
                  }, () => {
                    setShowErrorAlert(true);
                  }));
                }} />
            }
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton onClick={() => setCreatingStandardBlock(true)}>Create New Standard Block</IonButton>
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(standardBlock.error?.data?.message) ? standardBlock.error?.data?.message?.join('</br>') : 'Error Modifying Standard Block'}
      />}
    </IonPage>
  );
};

export default AdminBusinessDetailStandardBlocks;
