import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { FileUploadTypes } from "../../enums";
import FileUpload from "./FileUpload";
// import FileUpload from "./FileUpload";
import FormInput from "./FormInput";
import ModalCloseButton from "./ModalCloseButton";

import RichTextEditor from "./RichTextEditor";
import SelectInput from "./SelectInput";

interface Props {
  title: string,
  name: string,
  availableOptions?: string[] | undefined,
  // image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
  submitText: string,
  // onSubmitClick: (name: string, image: string, content: string, linkUrl: string) => void,
  onSubmitClick: (name: string, type: string, content: string, linkUrl: string, pdf: string) => void,
  onDeleteClick?: () => void,
  onCloseClick: () => void,
}

const EditStandardBlockModal: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>(props.name);
  // const [image, setImage] = useState<string>(props.image);
  const [type, setType] = useState<string>(props.type);
  const [content, setContent] = useState<string>(props.content);
  const [linkUrl, setLinkUrl] = useState<string>(props.linkUrl);
  const [pdf, setPdf] = useState<string>(props.pdf);

  const [searchText, setSearchText] = useState<string>('');

  return (
    <IonModal isOpen={true} onDidDismiss={props.onCloseClick}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <ModalCloseButton onClick={props.onCloseClick} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {props.availableOptions ? <>
          <IonItem>
            <IonLabel color="dark" position="stacked">Name</IonLabel>
            <IonInput
              className="dark-disabled-input"
              color="dark"
              disabled={true}
              value={name}
              placeholder="Pick From Items Below"
            />
            <IonInput
              color="dark"
              value={searchText}
              placeholder="Search"
              onIonChange={e => setSearchText(e.detail.value as string)}
            />
          </IonItem>
          <div
            className="show-scroll-bar"
            style={{
              height: 170,
              overflow: 'auto',
              paddingLeft: 30,
            }}>
            {props.availableOptions
              .filter((option) => (
                searchText.length === 0
                || option.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
              ))
              .map((option) => (
              <IonItem key={option} onClick={() => setName(option)}>
                <IonLabel>{option}</IonLabel>
              </IonItem>
            ))}
          </div>
        </> : <IonItem>
          <IonLabel position="stacked">Name</IonLabel>
          <IonInput disabled={true} value={name} />
        </IonItem>}
        {/* <FileUpload
          label="Image (16:9 aspect ratio)"
          type={FileUploadTypes.image}
          fileUrl={image}
          buttonText="Upload"
          onChange={url => setImage(url)}
          onDelete={() => setImage('')}
        /> */}
        <SelectInput
          label="Type"
          placeholder="Select one"
          value={type}
          options={[
            {
              label: 'Modal',
              value: 'content'
            },
            {
              label: 'External Link',
              value: 'external'
            },
            {
              label: 'PDF',
              value: 'pdf'
            },
          ]}
          onIonChange={e => setType(e.detail.value)}
        />
        {type === 'external' && <FormInput
          label="Link Url"
          value={linkUrl}
          placeholder="Enter Input"
          onIonChange={e => setLinkUrl(e.detail.value as string)}
        />}
        {type === 'content' && <>
          <IonItemDivider style={{borderBottom: 0}}>
            <IonLabel color="dark">
              Modal Content
            </IonLabel>
          </IonItemDivider>
          <RichTextEditor
            content={content}
            onChange={setContent}
          />
        </>}
        {type === 'pdf' && <FileUpload
          label="PDF File"
          type={FileUploadTypes.pdf}
          fileUrl={pdf}
          buttonText="Upload"
          onChange={url => setPdf(url)}
          onDelete={() => setPdf('')}
        />}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton
          disabled={
            name.length === 0
            || type.length === 0
            || (type === 'content' && content.length === 0)
            || (type === 'external' && linkUrl.length === 0)
            || (type === 'pdf' && pdf.length === 0)
          }
          onClick={() => props.onSubmitClick(name, type, content, linkUrl, pdf)}>
          {props.submitText}
        </IonButton>
        {props.onDeleteClick && <IonButton color="danger" onClick={props.onDeleteClick}>Delete</IonButton>}
      </IonFooter>
    </IonModal>
  )
}

export default EditStandardBlockModal;
