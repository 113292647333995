import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import SelectInput from "./SelectInput";
// import FileUpload from "./FileUpload";
import FormInput from "./FormInput";
import ModalCloseButton from "./ModalCloseButton";
import { useDispatch, useSelector } from "react-redux";
import { createBusiness } from "../../redux/actions/businessActions";

interface Props {
  isOpen: boolean;
  title: string;
  // onSubmitClick: (name: string, image: string, content: string, linkUrl: string) => void,
  onCloseClick: () => void,
  onSuccess: () => void,
}

const CreateBusinessModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    business,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
  }));

  const [name, setName] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.onCloseClick}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <ModalCloseButton onClick={props.onCloseClick} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FormInput
          label="Name"
          value={name}
          placeholder="Enter Input"
          onIonChange={e => setName(e.detail.value as string)}
        />
        <FormInput
          label="Slug"
          value={slug}
          placeholder="Enter Input"
          onIonChange={e => setSlug(e.detail.value as string)}
        />
        <SelectInput
          label="Type"
          placeholder="Select One"
          value={type}
          options={[
            {
              label: 'Hotel',
              value: 'hotel',
            },
            {
              label: 'Restaurant',
              value: 'restaurant',
            },
          ]}
          onIonChange={e => setType(e.detail.value)}
        />
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton disabled={name.length === 0 || slug.length === 0 || type.length === 0} onClick={() => {
          dispatch(createBusiness(
            {
              name,
              slug,
              type,
            },
            () => {
              setName('');
              setSlug('');
              setType('');
              props.onSuccess();
            },
            () => setShowErrorAlert(true),
          ));
        }}>Create</IonButton>
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(business.error?.data?.message) ? business.error?.data?.message?.join('</br>') : 'Error Creating Business'}
      />}
    </IonModal>
  )
}

export default CreateBusinessModal;
