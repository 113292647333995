import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { fetchBusinessById } from '../redux/actions/businessActions';
import { createFeaturedBlock } from '../redux/actions/featuredBlockActions';
import EditFeaturedBlockModal from '../components/EditFeaturedBlockModal';
import AdminHeader from '../shared/components/AdminHeader';
import AdminBusinessMenu from '../shared/components/AdminBusinessMenu';

interface Props extends RouteComponentProps<{
  id: string;
}> {}

const AdminBusinessDetailFeaturedBlocks: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business,
    featuredBlock,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
    featuredBlock: store.featuredBlockReducer,
  }));

  useEffect(() => {
    // fetch business when id param changes
    dispatch(fetchBusinessById(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  const [creatingFeaturedBlock, setCreatingFeaturedBlock] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Business Detail" />
      <IonContent fullscreen class="ion-padding">
        {!business.business ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <AdminBusinessMenu businessId={business.business.id} />
            <IonText>
              <h1>Featured Blocks (for display on the landing page)</h1>
            </IonText>
            {business.business.featuredBlocks.map((featuredBlock: any) => (
              <IonItem key={featuredBlock.id} routerLink={`/admin/businesses/${business.business?.id}/featured-blocks/${featuredBlock.id}`}>
                <IonLabel>{featuredBlock.name}</IonLabel>
              </IonItem>
            ))}
            {creatingFeaturedBlock &&
              <EditFeaturedBlockModal
                title="Create Featured Block"
                name=""
                image=""
                type=""
                content=""
                linkUrl=""
                pdf=""
                submitText="Create"
                onCloseClick={() => setCreatingFeaturedBlock(false)}
                onSubmitClick={(name: string, image: string, type: string, content: string, linkUrl: string, pdf: string) => {
                  return dispatch(createFeaturedBlock({ name, image, type, content, linkUrl, pdf, businessId: business.business.id }, () => {
                    setCreatingFeaturedBlock(false);
                    dispatch(fetchBusinessById(business.business.id));
                  }, () => {
                    setShowErrorAlert(true);
                  }));
                }} />
            }
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton onClick={() => setCreatingFeaturedBlock(true)}>Create New Featured Block</IonButton>
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(featuredBlock.error?.data?.message) ? featuredBlock.error?.data?.message?.join('</br>') : 'Error Modifying Featured Block'}
      />}
    </IonPage>
  );
};

export default AdminBusinessDetailFeaturedBlocks;
