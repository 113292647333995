import {
  IonSplitPane,
  IonRouterOutlet
} from '@ionic/react';
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import AdminMenu from '../shared/components/AdminMenu';
import AdminBusinessDetail from './AdminBusinessDetail';
import AdminBusinesses from './AdminBusinesses';
import AdminHome from './AdminHome';
import AdminUsers from './AdminUsers';
import AdminUserDetail from './AdminUserDetail';
import { fetchCurrentProfile } from '../redux/actions/profileActions';
import { useDispatch } from 'react-redux';
import AdminBusinessDetailLanding from './AdminBusinessDetailLanding';
import AdminBusinessDetailFeaturedBlocks from './AdminBusinessDetailFeaturedBlocks';
import AdminBusinessDetailStandardBlocks from './AdminBusinessDetailStandardBlocks';
import AdminBusinessDetailFeaturedBlockDetail from './AdminBusinessDetailFeaturedBlockDetail';
import AdminBusinessDetailStandardBlockDetail from './AdminBusinessDetailStandardBlockDetail';

import './Admin.css';

const Admin: React.FC = () => {
  const dispatch = useDispatch();
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      dispatch(fetchCurrentProfile());
    });
  }, [dispatch]);

  return authState === AuthState.SignedIn && user ? (
    <IonSplitPane contentId="admin">
      <AdminMenu />
      <IonRouterOutlet id="admin">
        <Route exact path="/admin" component={AdminHome} />
        <Route exact path="/admin/businesses" component={AdminBusinesses} />
        <Route exact path="/admin/businesses/:id" component={AdminBusinessDetail} />
        <Route exact path="/admin/businesses/:id/landing" component={AdminBusinessDetailLanding} />
        <Route exact path="/admin/businesses/:id/featured-blocks" component={AdminBusinessDetailFeaturedBlocks} />
        <Route exact path="/admin/businesses/:id/featured-blocks/:blockId" component={AdminBusinessDetailFeaturedBlockDetail} />
        <Route exact path="/admin/businesses/:id/standard-blocks" component={AdminBusinessDetailStandardBlocks} />
        <Route exact path="/admin/businesses/:id/standard-blocks/:blockId" component={AdminBusinessDetailStandardBlockDetail} />
        <Route exact path="/admin/users" component={AdminUsers} />
        <Route exact path="/admin/users/:id" component={AdminUserDetail} />
      </IonRouterOutlet>
    </IonSplitPane>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in">
        <div slot="secondary-footer-content"></div>
      </AmplifySignIn>
    </AmplifyAuthenticator>
  );
}

export default Admin;
