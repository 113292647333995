import { IonHeader, IonRouterLink, IonToolbar } from "@ionic/react"
import React from "react"

import './Header.css';

interface Props {
  slug: string;
  name: string;
  logo: string;
}

const Header: React.FC<Props> = (props) => {
  const {
    slug,
    name,
    logo,
  } = props;

  return (
    <IonHeader>
      <IonToolbar class="header">
        <IonRouterLink routerLink={`/business/${slug}`} class="logo-container">
          <img src={logo} alt={`${name} Logo`} />
        </IonRouterLink>
      </IonToolbar>
    </IonHeader>
  );
}

export default Header
