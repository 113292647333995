import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonModal,
  IonRouterLink,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { formatHtml } from "../../services/pageService";
import ModalCloseButton from "./ModalCloseButton";

import './BlockCard.css';

interface Props {
  title: string;
  image: string;
  type: string;
  content: string;
  linkUrl: string;
  pdf: string;
  slug: string;
  name: string;
  logo: string;
}

const BlockCard: React.FC<Props> = (props) => {
  const {
    title,
    image,
    type,
    content,
    linkUrl,
    pdf,
    slug,
    name,
    logo,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const cardContent = (
    <IonCard onClick={content ? () => setShowModal(true) : () => {}}>
      <div className="hero-container">
        <IonImg src={image} alt={title} class="hero-image" />
      </div>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
    </IonCard>
  );

  if (type === 'external') {
    return (
      <IonRouterLink href={linkUrl} target="_blank">
        {cardContent}
      </IonRouterLink>
    );
  } else if (type === 'pdf') {
    return (
      <IonRouterLink href={pdf} target="_blank">
        {cardContent}
      </IonRouterLink>
    );
  } else if (type === 'listing') {
    return (
      <IonRouterLink routerLink={`/business/${slug}/listing`}>
        {cardContent}
      </IonRouterLink>
    );
  } else if (type === 'content') {
    return (
      <>
        {cardContent}
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader class="header">
            <IonToolbar>
              {/* this is here to center the logo in the  modal header */}
              <div style={{display: 'inline-block', width: '70px'}}></div>
              <IonRouterLink routerLink={`/business/${slug}`} onClick={() => setShowModal(false)} class="logo-container">
                <img src={logo} alt={`${name} Logo`} />
              </IonRouterLink>
              <ModalCloseButton onClick={() => setShowModal(false)} />
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{__html: formatHtml(content)}}></div>
          </IonContent>
        </IonModal>
      </>
    );
  }

  return (
    cardContent
  );
}

export default BlockCard;
