import * as types from '../constants';
import API from '@aws-amplify/api';

export function createFeaturedBlock({ name, image, type, content, linkUrl, pdf, businessId }: {
  name: string,
  image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
  businessId: number,
}, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createFeaturedBlockStarted());

    API.post('admin', '/featured-blocks', {
      body: {
        enabled: false,
        name,
        image,
        type,
        content,
        linkUrl,
        pdf,
        businessId,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createFeaturedBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createFeaturedBlockError(error.response));
        onError();
      });
  };
};

export function updateFeaturedBlock({ id, enabled, name, image, type, content, linkUrl, pdf}: {
  id: number,
  enabled: boolean,
  name: string,
  image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
}, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createFeaturedBlockStarted());

    API.put('admin', `/featured-blocks/${id}`, {
      body: {
        id,
        enabled,
        name,
        image,
        type,
        content,
        linkUrl,
        pdf,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createFeaturedBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createFeaturedBlockError(error.response));
        onError();
      });
  };
};

export function deleteFeaturedBlock(id: number, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createFeaturedBlockStarted());

    API.del('admin', `/featured-blocks/${id}`, {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createFeaturedBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createFeaturedBlockError(error.response));
        onError();
      });
  };
};

const createFeaturedBlockStarted = () => ({
  type: types.CREATE_FEATURED_BLOCK_PENDING,
});

const createFeaturedBlockSuccess = (createdBusiness: any) => ({
  type: types.CREATE_FEATURED_BLOCK_SUCCESS,
  payload: {
    createdBusiness,
  },
});

const createFeaturedBlockError = (error: any) => ({
  type: types.CREATE_FEATURED_BLOCK_FAILURE,
  payload: {
    error,
  },
});