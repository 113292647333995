import { IBusiness } from '../../shared/classes/Business.class';
import * as types from '../constants';
import { BusinessActionTypes } from '../constants';

export interface IBusinessReducerInitState {
  loading: boolean,
  businessLoading: boolean,
  businessesLoading: boolean,
  businesses: IBusiness[],
  business: IBusiness | null,
  createdBusiness: IBusiness | null,
}

const initialState: IBusinessReducerInitState  = {
  loading: false,
  businessLoading: false,
  businessesLoading: false,
  businesses: [],
  createdBusiness: null,
  business: null,
};

export default function businessReducer(state: IBusinessReducerInitState = initialState, action: BusinessActionTypes) {
  switch (action.type) {
    case types.FETCH_BUSINESSES_PENDING:
      return {
        ...state,
        businessesLoading: true,
      };
    case types.FETCH_BUSINESSES_SUCCESS:
      return {
        ...state,
        businessesLoading: false,
        businesses: action.payload.businesses,
      };
    case types.FETCH_BUSINESSES_FAILURE:
      return {
        ...state,
        businessesLoading: false,
        businesses: [],
        error: action.payload.error,
      }

    case types.CREATE_BUSINESS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        createdBusiness: action.payload.createdBusiness,
      };
    case types.CREATE_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        createdBusiness: null,
        error: action.payload.error,
      }

    case types.UPDATE_BUSINESS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedBusiness: action.payload.updatedBusiness,
      };
    case types.UPDATE_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        updatedBusiness: null,
        error: action.payload.error,
      }

    case types.FETCH_BUSINESS_PENDING:
      return {
        ...state,
        businessLoading: true,
      };
    case types.FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        businessLoading: false,
        business: action.payload.business,
      };
    case types.FETCH_BUSINESS_FAILURE:
      return {
        ...state,
        businessLoading: false,
        business: null,
        error: action.payload.error,
      }
    default:
      return state;
  }
}