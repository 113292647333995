import * as types from '../constants';
import { API } from 'aws-amplify';

// export function createStandardBlock({ name, image, content, linkUrl, businessId }: {
export function createStandardBlock({ name, type, content, linkUrl, pdf, businessId }: {
  name: string,
  // image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
  businessId: number,
}, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createStandardBlockStarted());

    API.post('admin', '/standard-blocks', {
      body: {
        enabled: false,
        name,
        // image,
        type,
        content,
        linkUrl,
        pdf,
        businessId,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createStandardBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createStandardBlockError(error.response));
        onError();
      });
  };
};

// export function updateStandardBlock({ id, name, image, content, linkUrl, businessId }: {
export function updateStandardBlock({ id, enabled, name, type, content, linkUrl, pdf }: {
  id: number,
  enabled: boolean,
  name: string,
  // image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
}, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createStandardBlockStarted());

    API.put('admin', `/standard-blocks/${id}`, {
      body: {
        id,
        enabled,
        name,
        // image,
        type,
        content,
        linkUrl,
        pdf,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createStandardBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createStandardBlockError(error.response));
        onError();
      });
  };
};

export function deleteStandardBlock(id: number, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createStandardBlockStarted());

    API.del('admin', `/standard-blocks/${id}`, {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createStandardBlockSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createStandardBlockError(error.response));
        onError();
      });
  };
};

const createStandardBlockStarted = () => ({
  type: types.STANDARD_BLOCK_CREATE_PENDING,
});

const createStandardBlockSuccess = (createdBusiness: any) => ({
  type: types.STANDARD_BLOCK_CREATE_SUCCESS,
  payload: {
    createdBusiness,
  },
});

const createStandardBlockError = (error: any) => ({
  type: types.STANDARD_BLOCK_CREATE_FAILURE,
  payload: {
    error,
  },
});

export function getAvailableStandardBlocks(businessId: number, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(standardBlockGetAvailableOptionsPending());

    API.get('admin', `/standard-blocks/available-options/${businessId}`, {
      response: true
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(standardBlockGetAvailableOptionsSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(standardBlockGetAvailableOptionsFailure(error.response));
        onError();
      });
  };
};

const standardBlockGetAvailableOptionsPending = () => ({
  type: types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_PENDING,
});

const standardBlockGetAvailableOptionsSuccess = (availableOptions: any) => ({
  type: types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_SUCCESS,
  payload: {
    availableOptions,
  },
});

const standardBlockGetAvailableOptionsFailure = (error: any) => ({
  type: types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_FAILURE,
  payload: {
    error,
  },
});
