import { IBusiness } from "../shared/classes/Business.class";

export const USER_LOGOUT = 'USER_LOGOUT';

export const FETCH_BUSINESSES_PENDING = 'FETCH_BUSINESSES_PENDING';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE';

interface FetchBusinessesPendingAction {
  type: typeof FETCH_BUSINESSES_PENDING;
}
interface FetchBusinessesSuccessAction {
  type: typeof FETCH_BUSINESSES_SUCCESS;
  payload: {
    businesses: IBusiness[]
  }
}interface FetchBusinessesFailureAction {
  type: typeof FETCH_BUSINESSES_FAILURE;
  payload: {
    error: any;
  }
}

export const CREATE_BUSINESS_PENDING = 'CREATE_BUSINESS_PENDING';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE';

interface CreateBusinessPendingAction {
  type: typeof CREATE_BUSINESS_PENDING;
}
interface CreateBusinessSuccessAction {
  type: typeof CREATE_BUSINESS_SUCCESS;
  payload: {
    createdBusiness: IBusiness;
  }
}
interface CreateBusinessFailureAction {
  type: typeof CREATE_BUSINESS_FAILURE;
  payload: {
    error: any;
  }
}

export const UPDATE_BUSINESS_PENDING = 'UPDATE_BUSINESS_PENDING';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';

interface UpdateBusinessPendingAction {
  type: typeof UPDATE_BUSINESS_PENDING;
}
interface UpdateBusinessSuccessAction {
  type: typeof UPDATE_BUSINESS_SUCCESS;
  payload: {
    updatedBusiness: IBusiness;
  }
}
interface UpdateBusinessFailureAction {
  type: typeof UPDATE_BUSINESS_FAILURE;
  payload: {
    error: any;
  }
}

export const FETCH_BUSINESS_PENDING = 'FETCH_BUSINESS_PENDING';
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_FAILURE = 'FETCH_BUSINESS_FAILURE';

interface FetchBusinessPendingAction {
  type: typeof FETCH_BUSINESS_PENDING;
}interface FetchBusinessSuccessAction {
  type: typeof FETCH_BUSINESS_SUCCESS;
  payload: {
    business: IBusiness;
  }
}
interface FetchBusinessFailureAction {
  type: typeof FETCH_BUSINESS_FAILURE;
  payload: {
    error: any;
  }
}

export type BusinessActionTypes = FetchBusinessPendingAction | FetchBusinessSuccessAction | FetchBusinessFailureAction | CreateBusinessPendingAction | CreateBusinessSuccessAction | CreateBusinessFailureAction | 
                                  FetchBusinessesPendingAction | FetchBusinessesSuccessAction | FetchBusinessesFailureAction | UpdateBusinessSuccessAction | UpdateBusinessFailureAction | UpdateBusinessPendingAction;

export const CREATE_FEATURED_BLOCK_PENDING = 'CREATE_FEATURED_BLOCK_PENDING';
export const CREATE_FEATURED_BLOCK_SUCCESS = 'CREATE_FEATURED_BLOCK_SUCCESS';
export const CREATE_FEATURED_BLOCK_FAILURE = 'CREATE_FEATURED_BLOCK_FAILURE';

export const STANDARD_BLOCK_CREATE_PENDING = 'STANDARD_BLOCK_CREATE_PENDING';
export const STANDARD_BLOCK_CREATE_SUCCESS = 'STANDARD_BLOCK_CREATE_SUCCESS';
export const STANDARD_BLOCK_CREATE_FAILURE = 'STANDARD_BLOCK_CREATE_FAILURE';
export const STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_PENDING = 'STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_PENDING';
export const STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_SUCCESS = 'STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_SUCCESS';
export const STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_FAILURE = 'STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_FAILURE';

export const PROFILE_GET_ALL_PENDING = 'PROFILE_GET_ALL_PENDING';
export const PROFILE_GET_ALL_SUCCESS = 'PROFILE_GET_ALL_SUCCESS';
export const PROFILE_GET_ALL_FAILURE = 'PROFILE_GET_ALL_FAILURE';
export const PROFILE_GET_PENDING = 'PROFILE_GET_PENDING';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE';
export const PROFILE_GET_CURRENT_PENDING = 'PROFILE_GET_CURRENT_PENDING';
export const PROFILE_GET_CURRENT_SUCCESS = 'PROFILE_GET_CURRENT_SUCCESS';
export const PROFILE_GET_CURRENT_FAILURE = 'PROFILE_GET_CURRENT_FAILURE';
export const PROFILE_CREATE_PENDING = 'PROFILE_CREATE_PENDING';
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS';
export const PROFILE_CREATE_FAILURE = 'PROFILE_CREATE_FAILURE';
export const PROFILE_UPDATE_PENDING = 'PROFILE_UPDATE_PENDING';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';
