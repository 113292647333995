import {
  IonButton,
  IonButtons,
  IonToolbar,
} from '@ionic/react';
import React from 'react';

interface Props {
  businessId: number;
}

const AdminBusinessMenu: React.FC<Props> = (props) => {
  return (
    <IonToolbar>
      <IonButtons>
        <IonButton routerLink={`/admin/businesses/${props.businessId}`}>
          Business Information
        </IonButton>
        <IonButton routerLink={`/admin/businesses/${props.businessId}/landing`}>
          Landing Page
        </IonButton>
        <IonButton routerLink={`/admin/businesses/${props.businessId}/featured-blocks`}>
          Featured Blocks
        </IonButton>
        <IonButton routerLink={`/admin/businesses/${props.businessId}/standard-blocks`}>
          Standard Blocks
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default AdminBusinessMenu;
