import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRouterLink,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import AdminHeader from '../shared/components/AdminHeader';

const AdminHome: React.FC = () => {
  const {
    profile,
  } = useSelector((store: any) => ({
    profile: store.profileReducer,
  }));

  return (
    <IonPage>
      <AdminHeader title="Home" />
      <IonContent class="ion-padding">
        <IonGrid>
          <IonRow>
            {profile.currentProfile?.role === 'admin' && <>
              <IonCol>
                <IonRouterLink routerLink="/admin/Businesses">
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>Businesses</IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </IonRouterLink>
              </IonCol>
              <IonCol>
                <IonRouterLink routerLink="/admin/users">
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>Users</IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </IonRouterLink>
              </IonCol>
            </>}
            {profile.currentProfile?.role === 'customer' && profile.currentProfile?.business && <>
              <IonCol>
                <IonRouterLink routerLink={`/admin/businesses/${profile.currentProfile?.business?.id}`}>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>Business</IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </IonRouterLink>
              </IonCol>
            </>}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AdminHome;
