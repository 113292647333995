import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import SelectInput from "./SelectInput";
// import FileUpload from "./FileUpload";
import FormInput from "./FormInput";
import ModalCloseButton from "./ModalCloseButton";
import { useDispatch, useSelector } from "react-redux";
import { createProfile } from "../../redux/actions/profileActions";

interface Props {
  isOpen: boolean;
  title: string;
  // onSubmitClick: (name: string, image: string, content: string, linkUrl: string) => void,
  onCloseClick: () => void,
  onSuccess: () => void,
}

const CreateProfileModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    profile,
    business,
  } = useSelector((store: any) => ({
    profile: store.profileReducer,
    business: store.businessReducer,
  }));

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [businessId, setBusinessId] = useState<string>('');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.onCloseClick}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <ModalCloseButton onClick={props.onCloseClick} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FormInput
          label="Email"
          value={email}
          placeholder="Enter Input"
          onIonChange={e => setEmail(e.detail.value as string)}
        />
        <FormInput
          label="First Name"
          value={firstName}
          placeholder="Enter Input"
          onIonChange={e => setFirstName(e.detail.value as string)}
        />
        <FormInput
          label="Last Name"
          value={lastName}
          placeholder="Enter Input"
          onIonChange={e => setLastName(e.detail.value as string)}
        />
        <SelectInput
          label="Role"
          placeholder="Select One"
          value={role}
          options={[
            {
              label: 'Admin',
              value: 'admin',
            },
            {
              label: 'Customer',
              value: 'customer',
            },
          ]}
          onIonChange={e => setRole(e.detail.value)}
        />
        {role === 'customer' && <SelectInput
          label="Business"
          placeholder="Select One"
          value={businessId}
          options={business.businesses?.map((business: any) => ({
            label: business.name,
            value: business.id,
          }))}
          onIonChange={e => setBusinessId(e.detail.value)}
        />}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton
        disabled={[email, firstName, lastName, role].filter(field => field.length === 0).length > 0}
        onClick={() => {
          dispatch(createProfile(
            {
              email,
              firstName,
              lastName,
              role,
              businessId: parseInt(businessId)
            },
            () => {
              setEmail('');
              setFirstName('');
              setLastName('');
              setRole('');
              setBusinessId('');
              props.onSuccess();
            },
            () => {
              setShowErrorAlert(true);
            }
          ));
        }}>Create</IonButton>
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={profile.error?.data?.message?.join('</br>') || 'Error Creating Profile'}
      />}
    </IonModal>
  )
}

export default CreateProfileModal;
