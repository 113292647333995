import * as types from '../constants';

const initialState: {
  loading: boolean,
  error: any,
} = {
  loading: false,
  error: null,
};


export default function featuredBlockReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.CREATE_FEATURED_BLOCK_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_FEATURED_BLOCK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_FEATURED_BLOCK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state;
  }
}