import {
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonRow,
  IonCol,
  IonLoading
} from '@ionic/react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import BlockCard from '../shared/components/BlockCard';
import Header from '../shared/components/Header';
import './Landing.css';
import {fetchBusinessBySlug} from '../redux/actions/businessActions';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/Footer';
import { IBlock } from '../interfaces';
import Announcement from '../components/Announcement';
import HeroImage from '../shared/components/HeroImage';
import { formatHtml } from '../services/pageService';

declare const location: any;

const stripHtml = (html: string) => {
      const temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

interface Props extends RouteComponentProps<{
  slug: string;
}> {}

const Landing: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business
  } = useSelector((store: any) => ({
    business: store.businessReducer
  }));

  // only fetch business from API if slug is different
  useEffect(() => {
    if (!business.business || business.business.slug !== match.params.slug) {
      dispatch(fetchBusinessBySlug(match.params.slug, (data) => {
        // if the business is null or not enabled, redirect to app homepage
        if (!data || !data.enabled) {
          location.href = '/';
        }
      }));
    }
  }, [business.business, dispatch, match.params.slug]);

  return (
    <IonPage>
      {business.loading || !business.business ? (
        <IonContent>
          <IonLoading isOpen={true} />
        </IonContent>
      ) : (
        <>
          <Header
            slug={match.params.slug}
            name={business.business.name}
            logo={business.business.logo}
          />
          <IonContent fullscreen>
            {/* announcement */}
            <Announcement
              slug={match.params.slug}
              name={business.business?.name}
              logo={business.business?.logo}
              text={business.business.announcementText}
              backgroundColor={business.business.announcementBGColor}
              textColor={business.business.announcementTextColor}
              type={business.business?.announcementType}
              content={business.business?.announcementContent}
              linkUrl={business.business?.announcementLinkUrl}
              pdf={business.business?.announcementPdf}
            />
            {business.business.mainImage && <HeroImage name={business.business.name} image={business.business.mainImage} />}
            {/* tagline */}
            {business.business?.taglineText && <IonToolbar style={{
              '--background': business.business?.taglineBackgroundColor,
              '--color': business.business?.taglineTextColor,
            }}>
              <IonTitle class="ion-padding">
                <div className="ion-text-wrap ion-text-center">
                  {business.business?.taglineText}
                </div>
              </IonTitle>
            </IonToolbar>}
            {stripHtml(business.business.mainContent) && <IonRow>
              <IonCol>
                <IonText color="dark">
                  <div dangerouslySetInnerHTML={{__html: formatHtml(business.business.mainContent)}}></div>
                </IonText>
              </IonCol>
            </IonRow>}
            <IonRow>
              {business.business.featuredBlocks.filter((block: IBlock) => block.enabled).map((featuredBlock: IBlock) => (
                <IonCol key={featuredBlock.id} size="6" sizeSm="6" sizeMd="4" sizeLg="3">
                  <BlockCard
                    key={featuredBlock.id}
                    title={featuredBlock.name}
                    type={featuredBlock.type}
                    image={featuredBlock.image}
                    content={featuredBlock.content}
                    linkUrl={featuredBlock.linkUrl}
                    pdf={featuredBlock.pdf}
                    slug={match.params.slug}
                    name={business.business.name}
                    logo={business.business.logo}
                  />
                </IonCol>
              ))}
            </IonRow>
            <Footer
              flipbookLink={business.business.flipbookLink}
              flipbookLinkBackgroundColor={business.business.announcementBGColor}
              flipbookLinkTextColor={business.business.announcementTextColor}
              name={business.business.name}
              address={business.business.address}
              city={business.business.city}
              state={business.business.state}
              zip={business.business.zip}
              phone={business.business.phone}
              website={business.business.website}
              facebook={business.business.facebook}
              instagram={business.business.instagram}
              twitter={business.business.twitter}
              linkedin={business.business.linkedin}
              youtube={business.business.youtube}
            />
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Landing;