import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { fetchBusinessById } from '../redux/actions/businessActions';
import AdminHeader from '../shared/components/AdminHeader';
import AdminBusinessMenu from '../shared/components/AdminBusinessMenu';
import FormInput from '../shared/components/FormInput';
import FileUpload from '../shared/components/FileUpload';
import SelectInput from '../shared/components/SelectInput';
import RichTextEditor from '../shared/components/RichTextEditor';
import { FileUploadTypes } from '../enums';
import { deleteFeaturedBlock, updateFeaturedBlock } from '../redux/actions/featuredBlockActions';

declare const location: any;

interface Props extends RouteComponentProps<{
  id: string;
  blockId: string;
}> {}

const AdminBusinessDetailFeaturedBlocksDetail: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business,
    featuredBlock,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
    featuredBlock: store.featuredBlockReducer,
  }));

  const block = business.business?.featuredBlocks
      .filter((block: any) => block.id === parseInt(match.params.blockId))[0];

  useEffect(() => {
    // fetch business when id param changes
    dispatch(fetchBusinessById(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    const block = business.business?.featuredBlocks
      .filter((block: any) => block.id === parseInt(match.params.blockId))[0];
    setEnabled(block?.enabled || false);
    setName(block?.name || '');
    setImage(block?.image || '');
    setType(block?.type || '');
    setContent(block?.content || '');
    setLinkUrl(block?.linkUrl || '');
    setPdf(block?.pdf || '');
  }, [business.business, match.params.blockId]);

  const [enabled, setEnabled] = useState<boolean>(block?.enabled || false); 
  const [name, setName] = useState<string>(block?.name || '');
  const [image, setImage] = useState<string>(block?.image || '');
  const [type, setType] = useState<string>(block?.type || '');
  const [content, setContent] = useState<string>(block?.content || '');
  const [linkUrl, setLinkUrl] = useState<string>(block?.linkUrl || '');
  const [pdf, setPdf] = useState<string>(block?.pdf || '');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Featured Block Detail" />
      <IonContent fullscreen class="ion-padding">
        {!business.business ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <AdminBusinessMenu businessId={business.business.id} />
            <IonText>
              <h1>Featured Block Detail (for display on the landing page)</h1>
            </IonText>
            <IonItem>
              <IonLabel position="stacked">Enabled</IonLabel>
              <IonCheckbox checked={enabled} onIonChange={e => setEnabled(e.detail.checked)} />
            </IonItem>
            <FormInput
              label="Name"
              value={name}
              placeholder="Enter Input"
              onIonChange={e => setName(e.detail.value as string)}
            />
            <FileUpload
              label="Image (16:9 aspect ratio)"
              type={FileUploadTypes.image}
              fileUrl={image}
              buttonText="Upload"
              onChange={url => setImage(url)}
              onDelete={() => setImage('')}
            />
            <SelectInput
              label="Type"
              placeholder="Select one"
              value={type}
              options={[
                {
                  label: 'A-Z Page',
                  value: 'listing'
                },
                {
                  label: 'Modal',
                  value: 'content'
                },
                {
                  label: 'External Link',
                  value: 'external'
                },
                {
                  label: 'PDF',
                  value: 'pdf'
                },
              ]}
              onIonChange={e => setType(e.detail.value)}
            />
            {type=== 'external' && <FormInput
              label="Link Url"
              value={linkUrl}
              placeholder="Enter Input"
              onIonChange={e => setLinkUrl(e.detail.value as string)}
            />}
            {type === 'content' && <>
              <IonItemDivider style={{borderBottom: 0}}>
                <IonLabel color="dark">
                  Modal Content
                </IonLabel>
              </IonItemDivider>
              <RichTextEditor
                content={content}
                onChange={setContent}
              />
            </>}
            {type === 'pdf' && <FileUpload
              label="PDF File"
              type={FileUploadTypes.pdf}
              fileUrl={pdf}
              buttonText="Upload"
              onChange={url => setPdf(url)}
              onDelete={() => setPdf('')}
            />}
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton routerLink={`/admin/businesses/${business.business?.id}/featured-blocks`}>
          Back
        </IonButton>
        <IonButton
          disabled={
            name.length === 0
            || type.length === 0
            || image.length === 0
            || (type === 'content' && content.length === 0)
            || (type === 'external' && linkUrl.length === 0)
            || (type === 'pdf' && pdf.length === 0)
          }
          onClick={() => {
            return dispatch(updateFeaturedBlock({ id: block?.id, enabled, name, image, type, content, linkUrl, pdf }, () => {
              dispatch(fetchBusinessById(business.business.id));
            }, () => {
              setShowErrorAlert(true);
            }));
          }}>
          Update
        </IonButton>
        <IonButton color="danger" onClick={() => {
          dispatch(deleteFeaturedBlock(block?.id, () => {
            location.href = `/admin/businesses/${business.business?.id}/featured-blocks`;
            dispatch(fetchBusinessById(business.business.id));
          }))
        }}>Delete</IonButton>
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(featuredBlock.error?.data?.message) ? featuredBlock.error?.data?.message?.join('</br>') : 'Error Modifying Featured Block'}
      />}
    </IonPage>
  );
};

export default AdminBusinessDetailFeaturedBlocksDetail;
