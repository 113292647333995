import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { fetchBusinessById, updateBusiness } from '../redux/actions/businessActions';
import AdminHeader from '../shared/components/AdminHeader';
import AdminBusinessMenu from '../shared/components/AdminBusinessMenu';
import FormInput from '../shared/components/FormInput';
import SelectInput from '../shared/components/SelectInput';
import FileUpload from '../shared/components/FileUpload';
import RichTextEditor from '../shared/components/RichTextEditor';
import { FileUploadTypes } from '../enums';

interface Props extends RouteComponentProps<{
  id: string;
}> {}

const AdminBusinessDetailLanding: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
  }));

  useEffect(() => {
    // fetch business when id param changes
    dispatch(fetchBusinessById(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    setAnnouncementText(business.business?.announcementText || '');
    setAnnouncementBGColor(business.business?.announcementBGColor || '#fff');
    setAnnouncementTextColor(business.business?.announcementTextColor || '#000');
    setAnnouncementType(business.business?.announcementType || '');
    setAnnouncementContent(business.business?.announcementContent || '');
    setAnnouncementLinkUrl(business.business?.announcementLinkUrl || '');
    setAnnouncementPdf(business.business?.announcementPdf || '');
    setTaglineText(business.business?.taglineText || '');
    setTaglineTextColor(business.business?.taglineTextColor || '#000');
    setTaglineBackgroundColor(business.business?.taglineBackgroundColor || '#fff');
    setMainImage(business.business?.mainImage || '');
    setMainContent(business.business?.mainContent || '');
  }, [business.business]);

  const [announcementText, setAnnouncementText] = useState<string>(business.business?.announcementText || '');
  const [announcementBGColor, setAnnouncementBGColor] = useState<string>(business.business?.announcementBGColor || '#fff');
  const [announcementTextColor, setAnnouncementTextColor] = useState<string>(business.business?.announcementTextColor || '#000');
  const [announcementType, setAnnouncementType] = useState<string>(business.business?.announcementType || '');
  const [announcementContent, setAnnouncementContent] = useState<string>(business.business?.announcementContent || '');
  const [announcementLinkUrl, setAnnouncementLinkUrl] = useState<string>(business.business?.announcementLinkUrl || '');
  const [announcementPdf, setAnnouncementPdf] = useState<string>(business.business?.announcementPdf || '');
  const [taglineText, setTaglineText] = useState<string>(business.business?.taglineText || '');
  const [taglineTextColor, setTaglineTextColor] = useState<string>(business.business?.taglineTextColor || '#000');
  const [taglineBackgroundColor, setTaglineBackgroundColor] = useState<string>(business.business?.taglineBackgroundColor || '#fff');
  const [mainImage, setMainImage] = useState<string>(business.business?.mainImage || '');
  const [mainContent, setMainContent] = useState<string>(business.business?.mainContent || '');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Business Detail" />
      <IonContent fullscreen class="ion-padding">
        {!business.business ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <AdminBusinessMenu businessId={business.business.id} />
            <IonText>
              <h1>Business Landing Page</h1>
            </IonText>
            <FormInput
              label="Announcement Text"
              value={announcementText}
              placeholder="Enter Input"
              onIonChange={e => setAnnouncementText(e.detail.value as string)}
            />
            <FormInput
              label="Announcement Background Color (RGB or hex)"
              value={announcementBGColor}
              placeholder="Enter Input"
              onIonChange={e => setAnnouncementBGColor(e.detail.value as string)}
            />
            <SelectInput
              label="Announcement Text Color"
              placeholder="Select One"
              value={announcementTextColor}
              options={[
                {
                  label: 'Black',
                  value: '#000',
                },
                {
                  label: 'White',
                  value: '#fff',
                },
              ]}
              onIonChange={e => setAnnouncementTextColor(e.detail.value as string)}
            />
            <SelectInput
              label="Announcement Type"
              placeholder="Select One"
              value={announcementType}
              options={[
                {
                  label: 'Off',
                  value: 'off',
                },
                {
                  label: 'Content',
                  value: 'content',
                },
                {
                  label: 'External',
                  value: 'external',
                },
                {
                  label: 'PDF',
                  value: 'pdf',
                },
              ]}
              onIonChange={e => setAnnouncementType(e.detail.value as string)}
            />
            {announcementType === 'content' && <>
              <IonItemDivider style={{borderBottom: 'none'}}>
                <IonLabel color="dark">
                  Announcement Content
                </IonLabel>
              </IonItemDivider>
              <RichTextEditor
                content={announcementContent}
                onChange={setAnnouncementContent} 
              />
            </>}
            {announcementType === 'external' && <FormInput
              label="Announcement URL"
              value={announcementLinkUrl}
              placeholder="Enter Input"
              onIonChange={e => setAnnouncementLinkUrl(e.detail.value as string)}
            />}
            {announcementType === 'pdf' && <FileUpload
              label="Announcement PDF"
              type={FileUploadTypes.pdf}
              fileUrl={announcementPdf}
              buttonText="Upload"
              onChange={url => setAnnouncementPdf(url)}
              onDelete={() => setAnnouncementPdf('')}
            />}
            <FormInput
              label="Tagline Text"
              value={taglineText}
              placeholder="Enter Input"
              onIonChange={e => setTaglineText(e.detail.value as string)}
            />
            <SelectInput
              label="Tagline Text Color"
              placeholder="Select One"
              value={taglineTextColor}
              options={[
                {
                  label: 'Black',
                  value: '#000',
                },
                {
                  label: 'White',
                  value: '#fff',
                },
              ]}
              onIonChange={e => setTaglineTextColor(e.detail.value as string)}
            />
            <FormInput
              label="Tagline Background Color (RGB or hex)"
              value={taglineBackgroundColor}
              placeholder="Enter Input"
              onIonChange={e => setTaglineBackgroundColor(e.detail.value as string)}
            />
            <FileUpload
              label="Main Image (16:9 aspect ratio)"
              type={FileUploadTypes.image}
              fileUrl={mainImage}
              buttonText="Upload"
              onChange={url => setMainImage(url)}
              onDelete={() => setMainImage('')}
            />
            <IonItemDivider style={{borderBottom: 'none'}}>
              <IonLabel color="dark">
                Main Content
              </IonLabel>
            </IonItemDivider>
            <RichTextEditor
              content={mainContent}
              onChange={setMainContent} 
            />
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        {business.business && <IonButton
          disabled={
            (announcementType === 'content' && !announcementContent) ||
            (announcementType === 'pdf' && !announcementPdf) ||
            (announcementType === 'external' && !announcementLinkUrl)
          }
          onClick={() => dispatch(
            updateBusiness(
              {
                id: business.business.id,
                announcementText,
                announcementBGColor,
                announcementTextColor,
                announcementType,
                announcementContent,
                announcementLinkUrl,
                announcementPdf,
                taglineText,
                taglineTextColor,
                taglineBackgroundColor,
                mainImage,
                mainContent,
              },
              () => dispatch(fetchBusinessById(business.business.id)),
              () => {
                setShowErrorAlert(true);
              }
            )
          )}
        >Update</IonButton>}
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(business.error?.data?.message) ? business.error?.data?.message?.join('</br>') : 'Error Updating Business'}
      />}
    </IonPage>
  );
};

export default AdminBusinessDetailLanding;
