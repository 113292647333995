import {
  IonContent,
  IonPage,
  IonText,
  IonFooter,
  IonButton,
  IonList,
  IonItem,
  IonLoading,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { fetchBusinesses } from '../redux/actions/businessActions';
import { useSelector } from 'react-redux';
import { IBusiness } from '../shared/classes/Business.class';
import { useDispatch } from "react-redux";
import { IBusinessReducerInitState } from '../redux/reducers/businessReducer';
import CreateBusinessModal from '../shared/components/CreateBusinessModal';
import AdminHeader from '../shared/components/AdminHeader';

interface IAdminBusinessesState extends IBusinessReducerInitState {
  showCreateModal: boolean;
}

const AdminBusinesses: React.FC<{}> = (props) => {

  const dispatch = useDispatch();

  const {
    business
  } = useSelector((store: any) => ({
    business: store.businessReducer
  }));

  // const businessSelector = useSelector((store: any) => ({ businessSelector: store.businessReducer })).businessSelector;

  useEffect(() => {
    console.log('Landing.useEffect');
    dispatch(fetchBusinesses());
  }, [dispatch]);


  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Businesses" />
      <IonContent fullscreen class="ion-padding">
        <IonText color="dark">
          <h1>All Businesses</h1>
        </IonText>
        {business.businessesLoading || business.loading ? (
          <IonLoading isOpen={true} />
        ) : null}
        <IonList>
          {business.businesses.map((business: IBusiness) => (
            <IonItem key={business.id} routerLink={`/admin/businesses/${business.id}`}>
              <IonText>{business.name}</IonText>
            </IonItem>
          ))}
        </IonList>
        <CreateBusinessModal
          isOpen={showCreateModal}
          title="Create New Business"
          onCloseClick={() => setShowCreateModal(false)}
          onSuccess={() => {
            dispatch(fetchBusinesses());
            setShowCreateModal(false);
          }}
        />
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton onClick={() => setShowCreateModal(true)}>Create New Business</IonButton>
      </IonFooter>
    </IonPage>
  )
};
  
export default AdminBusinesses;
