import { IonFooter, IonIcon, IonRouterLink, IonText, IonToolbar } from "@ionic/react"
import React from "react"

import { logoFacebook, logoInstagram, logoLinkedin, logoTwitter, logoYoutube } from 'ionicons/icons';

import './Footer.css';

interface Props {
  flipbookLink: string;
  flipbookLinkTextColor: string;
  flipbookLinkBackgroundColor: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  linkedin: string;
  youtube: string;
}

const Footer: React.FC<Props> = (props) => {
  const {
    flipbookLink,
    flipbookLinkTextColor,
    flipbookLinkBackgroundColor,
    name,
    address,
    city,
    state,
    zip,
    phone,
    website,
  } = props;

  return (
    <IonFooter class="footer">
      {flipbookLink && <IonRouterLink href={flipbookLink} target="_blank" class="local-information">
        <IonToolbar
          class="flipbook-container"
          style={{
            '--background': flipbookLinkBackgroundColor,
            '--color': flipbookLinkTextColor,
          }}>
          Local Information
        </IonToolbar>
      </IonRouterLink>}
      <div className="ion-padding">
        <div className="address">
          <div>{name}</div>
          <div>{address}</div>
          <div>
            <span>{city}</span>
            {city && state && <span>,</span>}
            <span> {state}</span>
            <span> {zip}</span>
          </div>
          <div>
            {phone && <IonRouterLink href={`tel:${phone}`}>{phone}</IonRouterLink>}
            {phone && website && <span> | </span>}
            {website && <IonRouterLink href={website} target="_blank">{website}</IonRouterLink>}
          </div>
        </div>
        <div className="socials">
          {props.facebook && <IonRouterLink href={props.facebook} target="_blank" class="social-link">
            <IonIcon icon={logoFacebook} />
          </IonRouterLink>}
          {props.instagram && <IonRouterLink href={props.instagram} target="_blank" class="social-link">
            <IonIcon icon={logoInstagram} />
          </IonRouterLink>}
          {props.twitter && <IonRouterLink href={props.twitter} target="_blank" class="social-link">
            <IonIcon icon={logoTwitter} />
          </IonRouterLink>}
          {props.linkedin && <IonRouterLink href={props.linkedin} target="_blank" class="social-link">
            <IonIcon icon={logoLinkedin} />
          </IonRouterLink>}
          {props.youtube && <IonRouterLink href={props.youtube} target="_blank" class="social-link">
            <IonIcon icon={logoYoutube} />
          </IonRouterLink>}
        </div>
        <div className="copyright">
          <IonText>All rights reserved &copy; 2020 {name}</IonText>
        </div>
        <div className="copyright">
          <img
            src={require('../assets/images/logo_realapp_small_white.png')}
            alt="RealApp Logo"
            height="20"
            width="20"
          />
          <IonText>Powered by REAL</IonText>
        </div>
      </div>
    </IonFooter>
  );
}

export default Footer
