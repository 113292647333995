import * as types from '../constants';

const initialState: {
  loading: boolean,
  error: any,
} = {
  loading: false,
  error: null,
};

export default function standardBlockReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.STANDARD_BLOCK_CREATE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.STANDARD_BLOCK_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.STANDARD_BLOCK_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_PENDING:
      return {
        ...state,
        loading: true,
      }
    case types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_SUCCESS:
      return {
        ...state,
        availableOptions: action.payload.availableOptions,
        loading: false,
      }
    case types.STANDARD_BLOCK_GET_AVAILABLE_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state;
  }
}