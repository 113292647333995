import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react"
import React from "react"

interface Props {
  label: string;
  value: string;
  placeholder: string;
  options: {
    label: string,
    value: string,
  }[],
  onIonChange: (e: any) => void;
}

const SelectInput: React.FC<Props> = (props) => {
  const {
    label,
    value,
    placeholder,
    options,
    onIonChange,
  } = props;

  return (
    <IonItem>
        <IonLabel position="stacked">{label}</IonLabel>
        <IonSelect value={value} placeholder={placeholder} onIonChange={onIonChange}>
          {options.map((option, index) => (
            <IonSelectOption key={index} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
  );
}

export default SelectInput;
