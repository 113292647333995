import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonLoading,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Announcement from '../components/Announcement';
import Footer from '../components/Footer';
import Header from '../shared/components/Header';
import { fetchBusinessBySlug } from '../redux/actions/businessActions';
import './Listing.css';
import BlockCard from '../shared/components/BlockCard';
import { IBlock } from '../interfaces';

declare const location: any;

interface Props extends RouteComponentProps<{
  slug: string;
}> {}

const Listing: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business
  } = useSelector((store: any) => ({
    business: store.businessReducer
  }));

  // only fetch business from API if slug is different
  useEffect(() => {
    if (!business.business || business.business.slug !== match.params.slug) {
      dispatch(fetchBusinessBySlug(match.params.slug, (data) => {
        // if the business is null or not enabled, redirect to app homepage
        if (!data || !data.enabled) {
          location.href = '/';
        }
      }));
    }
  }, [business.business, dispatch, match.params.slug]);

  const getHeaderText = (name: string, featuredBlocks: IBlock[]) => {
    const listingFeaturedBlocks = featuredBlocks.filter((featuredBlock: IBlock) => !featuredBlock.linkUrl && !featuredBlock.content);

    if (listingFeaturedBlocks.length === 0) {
      return business.name;
    } else {
      return listingFeaturedBlocks[0].name;
    }
  }

  return (
    <IonPage>
      {business.loading || !business.business ? (
        <IonContent>
          <IonLoading isOpen={true} />
        </IonContent>
      ) : (
        <>
          <Header
            slug={match.params.slug}
            name={business.business.name}
            logo={business.business.logo}
          />
          <IonContent fullscreen>
            {/* announcement */}
            <Announcement
              slug={match.params.slug}
              name={business.business?.name}
              logo={business.business?.logo}
              text={business.business.announcementText}
              backgroundColor={business.business.announcementBGColor}
              textColor={business.business.announcementTextColor}
              type={business.business?.announcementType}
              content={business.business?.announcementContent}
              linkUrl={business.business?.announcementLinkUrl}
              pdf={business.business?.announcementPdf}
            />
            <IonRow>
              <IonCol>
                <h1>
                  {getHeaderText(business.business.name, business.business.featuredBlocks)}
                </h1>
              </IonCol>
            </IonRow>
            <IonRow>
              {business.business.standardBlocks.filter((standardBlock: IBlock) => standardBlock.enabled).map((standardBlock: IBlock) => (
                <IonCol key={standardBlock.id} size="6" sizeSm="6" sizeMd="4" sizeLg="3">
                  <BlockCard
                    title={standardBlock.name}
                    // TODO, update this placeholder image to use the standard images when we have them
                    type={standardBlock.type}
                    image={standardBlock.image}
                    content={standardBlock.content}
                    linkUrl={standardBlock.linkUrl}
                    pdf={standardBlock.pdf}
                    slug={match.params.slug}
                    name={business.business.name}
                    logo={business.business.logo}
                  />
                </IonCol>
              ))}
            </IonRow>
            <Footer
              flipbookLink={business.business.flipbookLink}
              flipbookLinkBackgroundColor={business.business.announcementBGColor}
              flipbookLinkTextColor={business.business.announcementTextColor}
              name={business.business.name}
              address={business.business.address}
              city={business.business.city}
              state={business.business.state}
              zip={business.business.zip}
              phone={business.business.phone}
              website={business.business.website}
              facebook={business.business.facebook}
              instagram={business.business.instagram}
              twitter={business.business.twitter}
              linkedin={business.business.linkedin}
              youtube={business.business.youtube}
            />
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Listing;