import { combineReducers } from 'redux';

import * as types from '../constants'
import businessReducer from './businessReducer';
import featuredBlockReducer from './featuredBlockReducer';
import standardBlockReducer from './standardBlockReducer';
import profileReducer from './profileReducer';


const appReducer = combineReducers({
  businessReducer,
  featuredBlockReducer,
  standardBlockReducer,
  profileReducer,
})

const rootReducer = (state: any, action: any) => {
  console.log(action)
  if (action.type === types.USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;