import * as types from '../constants';
import { BusinessActionTypes } from '../constants';
import { IBusiness } from '../../shared/classes/Business.class';
import { API } from 'aws-amplify';
import { IUpdateBusiness } from '../../interfaces';

export function fetchBusinesses() {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchBusinessesStarted());

    API.get('admin', '/businesses', {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchBusinessesSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchBusinessesFailure(error.response));
      });
  };
};

const fetchBusinessesStarted = (): BusinessActionTypes => ({
  type: types.FETCH_BUSINESSES_PENDING,
});

const fetchBusinessesSuccess = (businesses: IBusiness[]): BusinessActionTypes => ({
  type: types.FETCH_BUSINESSES_SUCCESS,
  payload: {
    businesses,
  },
});

const fetchBusinessesFailure = (error: any): BusinessActionTypes => ({
  type: types.FETCH_BUSINESSES_FAILURE,
  payload: {
    error,
  },
});

export function createBusiness({
  name,
  slug,
  type,
}: {
  name: string,
  slug: string,
  type: string,
}, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return async (dispatch: any, _getState: any) => {
    dispatch(createBusinessStarted());

    API.post('admin', '/businesses', {
      body: {
        name,
        slug,
        type,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createBusinessSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createBusinessError(error.response));
        onError();
      });
  };
};

const createBusinessStarted = (): BusinessActionTypes => ({
  type: types.CREATE_BUSINESS_PENDING,
});

const createBusinessSuccess = (createdBusiness: IBusiness): BusinessActionTypes => ({
  type: types.CREATE_BUSINESS_SUCCESS,
  payload: {
    createdBusiness,
  },
});

const createBusinessError = (error: any): BusinessActionTypes => ({
  type: types.CREATE_BUSINESS_FAILURE,
  payload: {
    error,
  },
});

export function fetchBusinessById(id: number, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchBusinessStarted());

    API.get('admin', `/businesses/${id}`, {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchBusinessSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchBusinessFailure(error.response));
        onError();
      });
  };
};

export function fetchBusinessBySlug(slug: string, onSuccess: (data: any) => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchBusinessStarted());

    API.get('public', `/businesses?slug=${slug}`, {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchBusinessSuccess(response.data));
        onSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchBusinessFailure(error.response));
        onError();
      });
  };
};

const fetchBusinessStarted = () => ({
  type: types.FETCH_BUSINESS_PENDING,
});

const fetchBusinessSuccess = (business: any) => ({
  type: types.FETCH_BUSINESS_SUCCESS,
  payload: {
    business,
  },
});

const fetchBusinessFailure = (error: any) => ({
  type: types.FETCH_BUSINESS_FAILURE,
  payload: {
    error,
  },
});

export function updateBusiness(business: IUpdateBusiness, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return async (dispatch: any, _getState: any) => {
    dispatch(updateBusinessStarted());

    API.put('admin', `/businesses/${business.id}`, {
      body: business,
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(updateBusinessSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateBusinessError(error.response));
        onError();
      });
  };
};

const updateBusinessStarted = (): BusinessActionTypes => ({
  type: types.UPDATE_BUSINESS_PENDING,
});

const updateBusinessSuccess = (updatedBusiness: IBusiness): BusinessActionTypes => ({
  type: types.UPDATE_BUSINESS_SUCCESS,
  payload: {
    updatedBusiness,
  },
});

const updateBusinessError = (error: any): BusinessActionTypes => ({
  type: types.UPDATE_BUSINESS_FAILURE,
  payload: {
    error,
  },
});
