import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useState } from "react";
import { Route } from "react-router-dom";
import Home from './pages/Home';
import Landing from "./pages/Landing";
import Listing from './pages/Listing';
import Admin from './pages/Admin';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getPageTitle } from "./services/pageService";

const Router: React.FC = () => {
  const {
    business
  } = useSelector((store: any) => ({
    business: store.businessReducer
  }));

  const [title, setTitle] = useState<string>('');

  return (
    <>
      <Helmet>
        <title>{getPageTitle({title})}</title>
      </Helmet>
      <IonReactRouter>
        <IonRouterOutlet id="admin">
          <Route
            exact
            path="/business/:slug"
            render={({match, history, location}) => {
              if (location.pathname.match(/^\/business\/[^/]+$/gi)) {
                setTitle(`${business.business?.name} - Home`);
              }
              return (
                <Landing match={match} history={history} location={location} />
              );
            }}
          />
          <Route
            exact 
            path="/business/:slug/listing"
            render={({match, history, location}) => {
              if (location.pathname.match(/^\/business\/.+\/listing$/gi)) {
                setTitle(`${business.business?.name || 'Business'} - A-Z`);
              }
              return (
                <Listing match={match} history={history} location={location} />
              )
            }}
          />
          <Route
            path="/admin" 
            render={({location}) => {
              if (location.pathname.match(/^\/admin?.+$/gi)) {
                setTitle('Admin');
              }
              return (
                <Admin />
              )
            }}
          />
          <Route
            exact
            path="/"
            render={({location}) => {
              if (location.pathname.match(/^\/$/gi)) {
                setTitle('Home');
              }
              return (
                <Home/>
              );
            }} />
        </IonRouterOutlet>
      </IonReactRouter>
    </>
  );
};

export default Router;
