import React from "react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  content: string;
  onChange: (e: any) => void;
}

const RichTextEditor: React.FC<Props> = (props) => {
  const {
    content,
    onChange,
  } = props;

  return (
    <ReactQuill
      value={content}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline'],
          [{color: []}],
          [{align: ['', 'center', 'right']}],
          ['link'],
          [{list: 'bullet'}],
          [{list: 'ordered'}],
          ['image', 'video'],
          ['clean']
        ]
      }}
      onChange={(html, delta, source, editor) => {
        onChange(html);
      }}
      style={{
        width: '100%',
        padding: 5,
      }}
    />
  );
}

export default RichTextEditor;
