export function getPageTitle({
  title
}: {
  title: string;
}) {
  return `${title} | RealApp`;
}

export function formatHtml(html: string) {
  // wrap videos to make them responsive
  let modifiedHtml = html.replace(/(<iframe class="ql-video".+?<\/iframe>)/gi, '<div class="responsive-video">$1</div>');
  return modifiedHtml;
}
