import {
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { closeOutline } from 'ionicons/icons';

interface Props {
  onClick: (e: any) => void;
}

const ModalCloseButton: React.FC<Props> = (props) => {
  return (
    <IonButton slot="end" fill="clear" color="dark" size="large" onClick={props.onClick}>
      <IonIcon icon={closeOutline} />
    </IonButton>
  )
};

export default ModalCloseButton;