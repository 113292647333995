import { IonItem, IonLabel, IonInput } from "@ionic/react"
import React from "react"

interface Props {
  label: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  onIonChange: (e: any) => void;
}

const FormInput: React.FC<Props> = (props) => {
  const {
    label,
    value,
    placeholder,
    disabled,
    onIonChange,
  } = props;

  return (
    <IonItem>
      <IonLabel color="dark" position="stacked">{label}</IonLabel>
      <IonInput color="dark" value={value} placeholder={placeholder} disabled={disabled} onIonChange={onIonChange}></IonInput>
    </IonItem>
  );
}

export default FormInput;
