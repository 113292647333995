import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify, { Auth } from 'aws-amplify';
import TagManager from 'react-gtm-module'

Amplify.configure({
  Auth:{
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, 
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION, 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, 
  },
  API: {
    endpoints: [
      {
        name: 'admin',
        endpoint: process.env.REACT_APP_API_BASE_URL,
        custom_header: async () => { 
          //return { Authorization : 'token' } 
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      },
      {
        name: 'public',
        endpoint: process.env.REACT_APP_API_BASE_URL,
      }
    ]
  }
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
