
import {
  IonContent,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import React from 'react';
import { briefcaseOutline, homeOutline, peopleOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';

const AdminMenu: React.FC = () => {
  const {
    profile
  } = useSelector((store: any) => ({
    profile: store.profileReducer
  }));

  return (
    <IonMenu contentId="admin">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem routerLink="/admin">
            <IonIcon slot="start" icon={homeOutline} />
            <IonLabel>Home</IonLabel>
          </IonItem>
          {profile.currentProfile?.role === 'admin' && <IonItem routerLink="/admin/businesses">
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonLabel>Businesses</IonLabel>
          </IonItem>}
          {profile.currentProfile?.role === 'admin' && <IonItem routerLink="/admin/users">
            <IonIcon slot="start" icon={peopleOutline} />
            <IonLabel>Users</IonLabel>
          </IonItem>}
          {profile.currentProfile?.role === 'customer' && profile.currentProfile?.business &&
          <IonItem routerLink={`/admin/businesses/${profile.currentProfile?.business?.id}`}>
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonLabel>Business Info</IonLabel>
          </IonItem>}
          {profile.currentProfile?.role === 'customer' && profile.currentProfile?.business &&
          <IonItem routerLink={`/admin/businesses/${profile.currentProfile?.business?.id}/landing`}>
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonLabel>Business Landing</IonLabel>
          </IonItem>}
          {profile.currentProfile?.role === 'customer' && profile.currentProfile?.business &&
          <IonItem routerLink={`/admin/businesses/${profile.currentProfile?.business?.id}/featured-blocks`}>
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonLabel>Business Featured Blocks</IonLabel>
          </IonItem>}
          {profile.currentProfile?.role === 'customer' && profile.currentProfile?.business &&
          <IonItem routerLink={`/admin/businesses/${profile.currentProfile?.business?.id}/standard-blocks`}>
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonLabel>Business Standard Blocks</IonLabel>
          </IonItem>}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default AdminMenu;
