import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import React from 'react';
import './Home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="container">
          <IonImg src="/assets/real-share-logo.png" alt={"RealApp Logo"} class="logo" />
          <br/>
          <br/>
          <IonButton href="/admin" color="dark">
            Login
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
