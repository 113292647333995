import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import FileUpload from "../shared/components/FileUpload";
import FormInput from "../shared/components/FormInput";

import RichTextEditor from "../shared/components/RichTextEditor";
import ModalCloseButton from "../shared/components/ModalCloseButton";
import SelectInput from "../shared/components/SelectInput";
import { FileUploadTypes } from "../enums";

interface Props {
  title: string,
  name: string,
  image: string,
  type: string,
  content: string,
  linkUrl: string,
  pdf: string,
  submitText: string,
  onSubmitClick: (name: string, image: string, type: string, content: string, linkUrl: string, pdf: string) => void,
  onDeleteClick?: () => void,
  onCloseClick: () => void,
}

const EditFeaturedBlockModal: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>(props.name);
  const [image, setImage] = useState<string>(props.image);
  const [type, setType] = useState<string>(props.type);
  const [content, setContent] = useState<string>(props.content);
  const [linkUrl, setLinkUrl] = useState<string>(props.linkUrl);
  const [pdf, setPdf] = useState<string>(props.pdf);

  return (
    <IonModal isOpen={true} onDidDismiss={props.onCloseClick}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <ModalCloseButton onClick={props.onCloseClick} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FormInput
          label="Name"
          value={name}
          placeholder="Enter Input"
          onIonChange={e => setName(e.detail.value as string)}
        />
        <FileUpload
          label="Image (16:9 aspect ratio)"
          type={FileUploadTypes.image}
          fileUrl={image}
          buttonText="Upload"
          onChange={url => setImage(url)}
          onDelete={() => setImage('')}
        />
        <SelectInput
          label="Type"
          placeholder="Select one"
          value={type}
          options={[
            {
              label: 'A-Z Page',
              value: 'listing'
            },
            {
              label: 'Modal',
              value: 'content'
            },
            {
              label: 'External Link',
              value: 'external'
            },
            {
              label: 'PDF',
              value: 'pdf'
            },
          ]}
          onIonChange={e => setType(e.detail.value)}
        />
        {type=== 'external' && <FormInput
          label="Link Url"
          value={linkUrl}
          placeholder="Enter Input"
          onIonChange={e => setLinkUrl(e.detail.value as string)}
        />}
        {type === 'content' && <>
          <IonItemDivider style={{borderBottom: 0}}>
            <IonLabel color="dark">
              Modal Content
            </IonLabel>
          </IonItemDivider>
          <RichTextEditor
            content={content}
            onChange={setContent}
          />
        </>}
        {type === 'pdf' && <FileUpload
          label="PDF File"
          type={FileUploadTypes.pdf}
          fileUrl={pdf}
          buttonText="Upload"
          onChange={url => setPdf(url)}
          onDelete={() => setPdf('')}
        />}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton
          disabled={
            name.length === 0
            || type.length === 0
            || image.length === 0
            || (type === 'content' && content.length === 0)
            || (type === 'external' && linkUrl.length === 0)
            || (type === 'pdf' && pdf.length === 0)
          }
          onClick={() => props.onSubmitClick(name, image, type, content, linkUrl, pdf)}>
          {props.submitText}
        </IonButton>
        {props.onDeleteClick && <IonButton color="danger" onClick={props.onDeleteClick}>Delete</IonButton>}
      </IonFooter>
    </IonModal>
  )
}

export default EditFeaturedBlockModal;
