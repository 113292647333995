import * as types from '../constants';
import { ICreateProfile, IUpdateProfile } from '../../interfaces';
import { API } from 'aws-amplify';

export function fetchProfiles() {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchProfilesPending());

    API.get('admin', '/profiles', {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchProfilesSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchProfilesFailure(error.response));
      });
  };
};

const fetchProfilesPending = () => ({
  type: types.PROFILE_GET_ALL_PENDING,
});

const fetchProfilesSuccess = (profiles: any) => ({
  type: types.PROFILE_GET_ALL_SUCCESS,
  payload: {
    profiles,
  },
});

const fetchProfilesFailure = (error: any) => ({
  type: types.PROFILE_GET_ALL_FAILURE,
  payload: {
    error,
  },
});

export function fetchProfileById(id: number, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchProfilePending());

    API.get('admin', `/profiles/${id}`, {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchProfileSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchProfileFailure(error.response));
        onError();
      });
  };
};

const fetchProfilePending = () => ({
  type: types.PROFILE_GET_PENDING,
});

const fetchProfileSuccess = (profile: any) => ({
  type: types.PROFILE_GET_SUCCESS,
  payload: {
    profile,
  },
});

const fetchProfileFailure = (error: any) => ({
  type: types.PROFILE_GET_FAILURE,
  payload: {
    error,
  },
});

export function createProfile(profile: ICreateProfile, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(createProfilePending());

    API.post('admin', '/profiles', {
      body: {
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        role: profile.role,
        business: profile.businessId,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(createProfileSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(createProfileError(error.response));
        onError();
      });
  };
};

const createProfilePending = () => ({
  type: types.PROFILE_CREATE_PENDING,
});

const createProfileSuccess = (createdProfile: any) => ({
  type: types.PROFILE_CREATE_SUCCESS,
  payload: {
    createdProfile,
  },
});

const createProfileError = (error: any) => ({
  type: types.PROFILE_CREATE_FAILURE,
  payload: {
    error,
  },
});

export function updateProfile(profile: IUpdateProfile, onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(updateProfilePending());

    API.put('admin', `/profiles/${profile.id}`, {
      body: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        role: profile.role,
        business: profile.businessId,
      },
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(updateProfileSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateProfileError(error.response));
        onError();
      });
  };
};

const updateProfilePending = () => ({
  type: types.PROFILE_UPDATE_PENDING,
});

const updateProfileSuccess = (updatedProfile: any) => ({
  type: types.PROFILE_UPDATE_SUCCESS,
  payload: {
    updatedProfile,
  },
});

const updateProfileError = (error: any) => ({
  type: types.PROFILE_UPDATE_FAILURE,
  payload: {
    error,
  },
});

export function fetchCurrentProfile(onSuccess: () => void = () => {}, onError: () => void = () => {}) {
  return (dispatch: any, _getState: any) => {
    dispatch(fetchCurrentProfilePending());

    API.get('admin', '/profiles/current', {
      response: true,
      // OPTIONAL
      // headers: {}, // OPTIONAL
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param'
      // }
    })
      .then((response) => {
        console.log(response);
        dispatch(fetchCurrentProfileSuccess(response.data));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchCurrentProfileFailure(error.response));
        onError();
      });
  };
};

const fetchCurrentProfilePending = () => ({
  type: types.PROFILE_GET_CURRENT_PENDING,
});

const fetchCurrentProfileSuccess = (profile: any) => ({
  type: types.PROFILE_GET_CURRENT_SUCCESS,
  payload: {
    profile,
  },
});

const fetchCurrentProfileFailure = (error: any) => ({
  type: types.PROFILE_GET_CURRENT_FAILURE,
  payload: {
    error,
  },
});
