import {
  IonButton,
  IonHeader,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/rootActions';

declare const location: any;

interface Props {
  title: string;
}

const AdminHeader: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    profile,
  } = useSelector((store: any) => ({
    profile: store.profileReducer,
  }));

  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>{props.title}</IonTitle>
        <IonLabel slot="end">{profile.currentProfile?.email}</IonLabel>
        <IonButton slot="end" fill="clear" color="dark" onClick={() => {
          Auth.signOut()
            .then(data => {
              dispatch(logout());
              location.href = '/admin';
            })
            .catch(err => console.log(err));
        }}>
        Log Out
      </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default AdminHeader;

