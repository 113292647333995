import {
  IonContent,
  IonPage,
  IonText,
  IonFooter,
  IonButton,
  IonList,
  IonItem,
  IonLoading,
  IonLabel,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import AdminHeader from '../shared/components/AdminHeader';
import CreateProfileModal from '../shared/components/CreateProfileModal';
import { fetchProfiles } from '../redux/actions/profileActions';
import { fetchBusinesses } from '../redux/actions/businessActions';

interface Props {

}

const AdminUsers: React.FC<Props> = (props) => {

  const dispatch = useDispatch();

  const {
    profile
  } = useSelector((store: any) => ({
    profile: store.profileReducer
  }));

  // const businessSelector = useSelector((store: any) => ({ businessSelector: store.businessReducer })).businessSelector;

  useEffect(() => {
    dispatch(fetchProfiles());
    dispatch(fetchBusinesses());
  }, [dispatch]);


  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="Users" />
      <IonContent fullscreen class="ion-padding">
        <IonText color="dark">
          <h1>All Users</h1>
        </IonText>
        {profile.loading ? (
          <IonLoading isOpen={true} />
        ) : null}
        <IonList>
          {profile.profiles.map((profile: any) => (
            <IonItem key={profile.id} routerLink={`/admin/users/${profile.id}`}>
              <IonLabel>
                <h3>{profile.firstName} {profile.lastName}</h3>
                <p>{profile.email}</p>
                <p>{profile.role}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        
        <CreateProfileModal
          isOpen={showCreateModal}
          title="Create New User"
          onCloseClick={() => setShowCreateModal(false)}
          onSuccess={() => {
            dispatch(fetchProfiles());
            setShowCreateModal(false);
          }}
        />
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        <IonButton onClick={() => setShowCreateModal(true)}>Create New User</IonButton>
      </IonFooter>
    </IonPage>
  )
};

export default AdminUsers;
