import { IonContent, IonHeader, IonModal, IonRouterLink, IonText, IonToolbar } from "@ionic/react"
import React, { useState } from "react"
import { formatHtml } from "../services/pageService";
import ModalCloseButton from "../shared/components/ModalCloseButton";

import './Announcement.css';

interface Props {
  slug: string;
  logo: string;
  name: string;
  text: string;
  backgroundColor: string;
  textColor: string;
  type: string;
  content: string;
  linkUrl: string;
  pdf: string;
}

const Announcement: React.FC<Props> = (props) => {
  const {
    slug,
    logo,
    name,
    text,
    backgroundColor,
    textColor,
    type,
    content,
    linkUrl,
    pdf,
  } = props;

  const [showModal, setShowModal] = useState(false);

  if (!text || !type || type === 'off') {
    return null;
  }

  const announcementInner = (
    <IonToolbar
      onClick={content ? () => setShowModal(true) : () => {}}
      class="announcement"
      style={{
        '--background': backgroundColor,
        '--color': textColor,
      }}>
      <IonText>
        <div className="ion-padding ion-text-wrap ion-text-center">{text}</div>
      </IonText>
    </IonToolbar>
  )

  if (type === 'content' && content) {
    return (
      <>
        {announcementInner}
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader class="header">
            <IonToolbar>
              {/* this is here to center the logo in the  modal header */}
              <div style={{display: 'inline-block', width: '70px'}}></div>
              <IonRouterLink routerLink={`/business/${slug}`} onClick={() => setShowModal(false)} class="logo-container">
                <img src={logo} alt={`${name} Logo`} />
              </IonRouterLink>
              <ModalCloseButton onClick={() => setShowModal(false)} />
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <div dangerouslySetInnerHTML={{__html: formatHtml(content)}}></div>
          </IonContent>
        </IonModal>
      </>
    );
  } else if (type === 'external' && linkUrl) {
    return (
      <IonRouterLink href={linkUrl} target="_blank">
        {announcementInner}
      </IonRouterLink>
    );
  } else if (type === 'pdf' && pdf) {
    return (
      <IonRouterLink href={pdf} target="_blank">
        {announcementInner}
      </IonRouterLink>
    );
  }

  return null;
}

export default Announcement
