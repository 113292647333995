import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import AdminHeader from '../shared/components/AdminHeader';
import { fetchProfileById, updateProfile } from '../redux/actions/profileActions';
import FormInput from '../shared/components/FormInput';
import SelectInput from '../shared/components/SelectInput';
import { fetchBusinesses } from '../redux/actions/businessActions';

interface Props extends RouteComponentProps<{
  id: string;
}> {}

const AdminUserDetail: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    profile,
    business,
  } = useSelector((store: any) => ({
    profile: store.profileReducer,
    business: store.businessReducer,
  }));

  useEffect(() => {
    // fetch profile when id param changes
    dispatch(fetchProfileById(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    dispatch(fetchBusinesses());
  }, [dispatch])

  useEffect(() => {
    setEmail(profile.profile?.email || '');
    setFirstName(profile.profile?.firstName || '');
    setLastName(profile.profile?.lastName || '');
    setRole(profile.profile?.role || '');
    setBusinessId(profile.profile?.business?.id || null);
  }, [profile.profile])

  const [email, setEmail] = useState<string>(profile.profile?.email || '');
  const [firstName, setFirstName] = useState<string>(profile.profile?.firstName || '');
  const [lastName, setLastName] = useState<string>(profile.profile?.lastName || '');
  const [role, setRole] = useState<string>(profile.profile?.role || '');
  const [businessId, setBusinessId] = useState<string>(profile.profile?.business?.id || null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <AdminHeader title="User Detail" />
      <IonContent fullscreen class="ion-padding">
        <IonText color="dark">
          <h1>User Customization</h1>
        </IonText>
        {!profile.profile ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <FormInput
              label="Email"
              value={email}
              placeholder="Enter Input"
              disabled={true}
              onIonChange={e => () => {}}
            />
            <FormInput
              label="First Name"
              value={firstName}
              placeholder="Enter Input"
              onIonChange={e => setFirstName(e.detail.value as string)}
            />
            <FormInput
              label="Last Name"
              value={lastName}
              placeholder="Enter Input"
              onIonChange={e => setLastName(e.detail.value as string)}
            />
            <SelectInput
              label="Role"
              placeholder="Select One"
              value={role}
              options={[
                {
                  label: 'Admin',
                  value: 'admin',
                },
                {
                  label: 'Customer',
                  value: 'customer',
                },
              ]}
              onIonChange={e => setRole(e.detail.value)}
            />
            {role === 'customer' && <SelectInput
              label="Business"
              placeholder="Select One"
              value={businessId}
              options={[{
                label: 'None',
                value: null,
              }, ...business.businesses?.map((business: any) => ({
                label: business.name,
                value: business.id,
              }))]}
              onIonChange={e => setBusinessId(e.detail.value)}
            />}
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        {profile.profile && <IonButton
          disabled={[firstName, lastName, role].filter(field => field.length === 0).length > 0}
          onClick={() => dispatch(
            updateProfile(
              {id: profile.profile.id, firstName, lastName, role, businessId: parseInt(businessId)},
              () => dispatch(fetchProfileById(profile.profile.id)),
              () => {
                setShowErrorAlert(true);
              }
            )
          )}
        >Update</IonButton>}
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={profile.error?.data?.message?.join('<br/>') || 'Error Updating Profile'}
      />}
    </IonPage>
  );
};

export default AdminUserDetail;
