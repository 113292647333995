import {
  IonItem,
  IonLabel,
  IonButton,
  IonImg,
  IonIcon,
  IonItemDivider,
  IonRouterLink
} from "@ionic/react"
import React, { useState } from "react"
import { cloudUploadOutline, documentAttachOutline } from 'ionicons/icons';
import axios from 'axios';

import './FileUpload.css';
import { FileUploadTypes } from "../../enums";

interface Props {
  label: string;
  type: FileUploadTypes;
  fileUrl?: string;
  buttonText: string;
  onChange: (url: string) => void;
  onDelete?: () => void;
}

const FileUpload: React.FC<Props> = (props) => {
  const {
    label,
    type,
    fileUrl,
    buttonText,
    onChange,
    onDelete,
  } = props;

  const [inputElement, setInputElement] = useState({} as HTMLInputElement);

  let accept = '';

  if (type === FileUploadTypes.image) {
    accept = 'image/*';
  } else if (type === FileUploadTypes.pdf) {
    accept = 'application/pdf';
  }

  return (
    <>
      <IonItemDivider style={{borderBottom: 'none'}}>
        <IonLabel color="dark">{label}</IonLabel>
      </IonItemDivider>
      <IonItem class="file-upload" style={{'--padding-start': 0}}>
        {fileUrl && type === FileUploadTypes.image && <IonImg src={fileUrl} class="thumbnail" />}
        {fileUrl && type === FileUploadTypes.pdf && <IonRouterLink href={fileUrl} target="_blank">
          <IonIcon icon={documentAttachOutline} color="dark" class="thumbnail-empty" />
          View PDF
        </IonRouterLink>}
        {!fileUrl && <IonIcon icon={cloudUploadOutline} color="dark" class="thumbnail-empty" />}
        <IonButton onClick={() => inputElement.click()} expand="block" slot="end">
          <input
            ref={input => (input !== null ? setInputElement(input) : null)}
            accept={accept}
            hidden
            type="file"
            onChange={async (e) => {
              const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0) || ({} as File);
              const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/media-upload/upload?type=${encodeURIComponent(file.type)}`);
              const { putUrl, Key } = response.data as { putUrl: string, Key: string };
              await axios.put(putUrl, file, { headers: { Key, 'Content-Type': file.type }});
              onChange(`https://${process.env.REACT_APP_S3_MEDIA_LIBRARY_BUCKET}.s3.amazonaws.com/${Key}`);
            }}
          />
          {buttonText}
        </IonButton>
        {onDelete && <IonButton expand="block" slot="end" color="danger" onClick={onDelete}>
          Delete
        </IonButton>}
      </IonItem>
    </>
  );
}

export default FileUpload;
