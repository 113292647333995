import * as types from '../constants';

const initialState: {
  loading: boolean,
  error: any,
  profiles: any[],
  profile: any,
} = {
  loading: false,
  error: null,
  profiles: [],
  profile: null,
};

export default function profileReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.PROFILE_GET_ALL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.PROFILE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: action.payload.profiles,
      };
    case types.PROFILE_GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        profiles: [],
        error: action.payload.error,
      }

    case types.PROFILE_GET_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.PROFILE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
      };
    case types.PROFILE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        profile: null,
        error: action.payload.error,
      }

    case types.PROFILE_CREATE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.PROFILE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case types.PROFILE_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case types.PROFILE_GET_CURRENT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.PROFILE_GET_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProfile: action.payload.profile,
      };
    case types.PROFILE_GET_CURRENT_FAILURE:
      return {
        ...state,
        loading: false,
        currentProfile: null,
        error: action.payload.error,
      }

    default:
      return state;
  }
}