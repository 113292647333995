import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import QRCode from 'qrcode.react';
import { fetchBusinessById, fetchBusinesses, updateBusiness } from '../redux/actions/businessActions';
import AdminHeader from '../shared/components/AdminHeader';
import AdminBusinessMenu from '../shared/components/AdminBusinessMenu';
import FormInput from '../shared/components/FormInput';
import FileUpload from '../shared/components/FileUpload';
import { FileUploadTypes } from '../enums';

interface Props extends RouteComponentProps<{
  id: string;
}> {}

const AdminBusinessDetail: React.FC<Props> = ({match}) => {
  const dispatch = useDispatch();
  const {
    business,
    profile,
  } = useSelector((store: any) => ({
    business: store.businessReducer,
    profile: store.profileReducer,
  }));

  useEffect(() => {
    // fetch business when id param changes
    dispatch(fetchBusinessById(parseInt(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    setEnabled(business.business?.enabled || false);
    setName(business.business?.name || '');
    setSlug(business.business?.slug || '');
    setLogo(business.business?.logo || '');
    setFlipbookLink(business.business?.flipbookLink || '');
    setAddress(business.business?.address || '');
    setCity(business.business?.city || '');
    setState(business.business?.state || '');
    setZip(business.business?.zip || '');
    setPhone(business.business?.phone || '');
    setWebsite(business.business?.website || '');
    setFacebook(business.business?.facebook || '');
    setInstagram(business.business?.instagram || '');
    setTwitter(business.business?.twitter || '');
    setLinkedin(business.business?.linkedin || '');
    setYoutube(business.business?.youtube || '');
  }, [business.business]);

  const [enabled, setEnabled] = useState<boolean>(business.business?.enabled || false);
  const [name, setName] = useState<string>(business.business?.name || '');
  const [slug, setSlug] = useState<string>(business.business?.slug || '');
  const [logo, setLogo] = useState<string>(business.business?.logo || '');
  const [flipbookLink, setFlipbookLink] = useState<string>(business.business?.flipbookLink || '');
  const [address, setAddress] = useState<string>(business.business?.address || '');
  const [city, setCity] = useState<string>(business.business?.city || '');
  const [state, setState] = useState<string>(business.business?.state || '');
  const [zip, setZip] = useState<string>(business.business?.zip || '');
  const [phone, setPhone] = useState<string>(business.business?.phone || '');
  const [website, setWebsite] = useState<string>(business.business?.website || '');
  const [facebook, setFacebook] = useState<string>(business.business?.facebook || '');
  const [instagram, setInstagram] = useState<string>(business.business?.instagram || '');
  const [twitter, setTwitter] = useState<string>(business.business?.twitter || '');
  const [linkedin, setLinkedin] = useState<string>(business.business?.linkedin || '');
  const [youtube, setYoutube] = useState<string>(business.business?.youtube || '');
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const QR_CODE_ID = 'business-qr-code';

  const downloadQrCode = () => {
    const canvas = document.getElementById(QR_CODE_ID) as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${slug}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <IonPage>
      <AdminHeader title="Business Detail" />
      <IonContent fullscreen class="ion-padding">
        {!business.business ? (
          <IonLoading isOpen={true} />
        ) : (
          <>
            <AdminBusinessMenu businessId={business.business.id} />
            <IonText>
              <h1>Business Information</h1>
              <p>Upload your logo, your favorite photo, select colors and fonts, and enter your copy to create your custom landing page.</p>
            </IonText>
            <div style={{textAlign: 'center'}}>
              <QRCode
                id={QR_CODE_ID}
                value={`${window.location.origin}/business/${slug}?utm_medium=qrcode`}
                size={290}
                includeMargin={true}
              />
              <br />
              <IonButton onClick={downloadQrCode}>Download QR Code</IonButton>
            </div>
            {profile.currentProfile?.role === 'admin' && <IonItem>
              <IonLabel position="stacked">Enabled</IonLabel>
              <IonCheckbox checked={enabled} onIonChange={e => setEnabled(e.detail.checked)} />
            </IonItem>}
            <FormInput
              label="Name"
              value={name}
              placeholder="Enter Input"
              onIonChange={e => setName(e.detail.value as string)}
            />
            {profile.currentProfile?.role === 'admin' && <FormInput
              label="Slug"
              value={slug}
              placeholder="Enter Input"
              onIonChange={e => setSlug(e.detail.value as string)}
            />}
            <FileUpload
              label="Logo Image"
              type={FileUploadTypes.image}
              fileUrl={logo}
              buttonText="Upload"
              onChange={url => setLogo(url)}
              onDelete={() => setLogo('')}
            />
            <FormInput
              label="Flipbook Link"
              value={flipbookLink}
              placeholder="Enter Input"
              onIonChange={e => setFlipbookLink(e.detail.value as string)}
            />
            <FormInput
              label="Address"
              value={address}
              placeholder="Enter Input"
              onIonChange={e => setAddress(e.detail.value as string)}
            />
            <FormInput
              label="City"
              value={city}
              placeholder="Enter Input"
              onIonChange={e => setCity(e.detail.value as string)}
            />
            <FormInput
              label="State"
              value={state}
              placeholder="Enter Input"
              onIonChange={e => setState(e.detail.value as string)}
            />
            <FormInput
              label="Zip"
              value={zip}
              placeholder="Enter Input"
              onIonChange={e => setZip(e.detail.value as string)}
            />
            <FormInput
              label="Phone"
              value={phone}
              placeholder="Enter Input"
              onIonChange={e => setPhone(e.detail.value as string)}
            />
            <FormInput
              label="Website"
              value={website}
              placeholder="Enter Input"
              onIonChange={e => setWebsite(e.detail.value as string)}
            />
            <FormInput
              label="Facebook Link"
              value={facebook}
              placeholder="Enter Input"
              onIonChange={e => setFacebook(e.detail.value as string)}
            />
            <FormInput
              label="Instgram Link"
              value={instagram}
              placeholder="Enter Input"
              onIonChange={e => setInstagram(e.detail.value as string)}
            />
            <FormInput
              label="Twitter Link"
              value={twitter}
              placeholder="Enter Input"
              onIonChange={e => setTwitter(e.detail.value as string)}
            />
            <FormInput
              label="LinkedIn Link"
              value={linkedin}
              placeholder="Enter Input"
              onIonChange={e => setLinkedin(e.detail.value as string)}
            />
            <FormInput
              label="YouTube Link"
              value={youtube}
              placeholder="Enter Input"
              onIonChange={e => setYoutube(e.detail.value as string)}
            />
          </>
        )}
      </IonContent>
      <IonFooter style={{textAlign: 'right'}}>
        {business.business && <IonButton
          disabled={[name, slug].filter(field => field.length === 0).length > 0}
          onClick={() => dispatch(
            updateBusiness(
              {
                id: business.business.id,
                enabled,
                name,
                slug,
                logo,
                flipbookLink,
                address,
                city,
                state,
                zip,
                phone,
                website,
                facebook,
                instagram,
                twitter,
                youtube,
                linkedin,
              },
              () => {
                dispatch(fetchBusinessById(business.business.id));
                dispatch(fetchBusinesses());
              },
              () => {
                setShowErrorAlert(true);
              }
            )
          )}
        >Update</IonButton>}
      </IonFooter>
      {showErrorAlert && <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={'Error'}
        subHeader={'Please Check Inputs'}
        message={Array.isArray(business.error?.data?.message) ? business.error?.data?.message?.join('</br>') : 'Error Updating Business'}
      />}
    </IonPage>
  );
};

export default AdminBusinessDetail;
