import { IonImg } from "@ionic/react"
import React from "react"

import './HeroImage.css';

interface Props {
  name: string;
  image: string;
}

const HeroImage: React.FC<Props> = (props) => {
  const { image, name } = props;

  return (
    <div className="hero-container">
      <IonImg src={image} alt={name} class="hero-image" />
    </div>
  );
}

export default HeroImage
